<template>
  <div class="chat-container">
    <div class="connection-area">
      <div class="status-bar">
        连接状态: {{ connectionStatus }}
      </div>
      <div class="my-id">
        我的ID: {{ myPeerId }}
      </div>
      <div class="connect-form">
        <input v-model="targetPeerId" placeholder="输入对方ID">
        <button @click="connectToPeer" :disabled="!targetPeerId">连接</button>
      </div>
    </div>

    <div class="chat-area">
      <div class="messages" ref="messageArea">
        <div v-for="(msg, index) in messages" 
             :key="index" 
             :class="['message', msg.from === 'me' ? 'sent' : 'received']">
          {{ msg.content }}
        </div>
      </div>
      
      <div class="input-area">
        <input v-model="newMessage" 
               @keyup.enter="sendMessage" 
               placeholder="输入消息...">
        <button @click="sendMessage">发送</button>
      </div>
    </div>
  </div>
</template>

<script>
import Peer from 'peerjs'

export default {
  name: 'ChatRoom',
  data() {
    return {
      peer: null,
      myPeerId: '',
      targetPeerId: '',
      connection: null,
      messages: [],
      newMessage: '',
      connectionStatus: '未连接'
    }
  },
  mounted() {
    this.initializePeer()
  },
  methods: {
    initializePeer() {
      const peerConfig = {
        debug: 3,
        config: {
          'iceServers': [
            { urls: 'stun:stun.l.google.com:19302' },
            { urls: 'stun:stun1.l.google.com:19302' },
            { urls: 'stun:stun2.l.google.com:19302' },
            { urls: 'stun:stun3.l.google.com:19302' },
            { urls: 'stun:stun4.l.google.com:19302' },
            // 建议添加自己的 TURN 服务器
            /*
            {
              urls: 'turn:你的turn服务器地址',
              username: '用户名',
              credential: '密码'
            }
            */
          ],
          'iceCandidatePoolSize': 10
        }
      }
      
      this.peer = new Peer(peerConfig)
      
      this.peer.on('open', (id) => {
        this.myPeerId = id
        this.connectionStatus = '等待连接'
      })

      this.peer.on('connection', (conn) => {
        this.connection = conn
        this.setupConnectionListeners()
      })

      this.peer.on('error', (err) => {
        console.error('Peer 错误:', err)
        this.connectionStatus = '连接错误: ' + err.type
        setTimeout(() => {
          this.initializePeer()
        }, 5000)
      })

      this.peer.on('disconnected', () => {
        this.connectionStatus = '连接断开'
        this.peer.reconnect()
      })
    },
    
    connectToPeer() {
      if (this.targetPeerId) {
        this.connectionStatus = '正在连接...'
        this.connection = this.peer.connect(this.targetPeerId, {
          reliable: true,
          serialization: 'json'
        })
        this.setupConnectionListeners()
      }
    },

    setupConnectionListeners() {
      if (!this.connection) return

      this.connection.on('open', () => {
        this.connectionStatus = '已连接'
        console.log('连接建立成功！')
      })

      this.connection.on('data', (data) => {
        this.messages.push({
          content: data,
          from: 'other'
        })
      })

      this.connection.on('close', () => {
        this.connectionStatus = '连接关闭'
      })

      this.connection.on('error', (err) => {
        console.error('连接错误:', err)
        this.connectionStatus = '连接错误'
      })
    },

    sendMessage() {
      if (this.newMessage.trim() && this.connection && this.connection.open) {
        try {
          this.connection.send(this.newMessage)
          this.messages.push({
            content: this.newMessage,
            from: 'me'
          })
          this.newMessage = ''
        } catch (err) {
          console.error('发送消息失败:', err)
          this.messages.push({
            content: '消息发送失败',
            from: 'system'
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.chat-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.connection-area {
  margin-bottom: 20px;
  padding: 10px;
  background: #f5f5f5;
  border-radius: 8px;
}

.chat-area {
  border: 1px solid #ddd;
  border-radius: 8px;
  height: 500px;
  display: flex;
  flex-direction: column;
}

.messages {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.message {
  margin: 10px 0;
  padding: 10px;
  border-radius: 8px;
  max-width: 70%;
}

.sent {
  background: #007bff;
  color: white;
  margin-left: auto;
}

.received {
  background: #e9ecef;
  margin-right: auto;
}

.input-area {
  padding: 20px;
  border-top: 1px solid #ddd;
  display: flex;
  gap: 10px;
}

input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  padding: 8px 16px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background: #0056b3;
}

.status-bar {
  background: #f8f9fa;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
  text-align: center;
}

.system-message {
  text-align: center;
  color: #666;
  font-size: 0.9em;
  margin: 10px 0;
}
</style> 